import React from "react";
import "../config/i18n";

import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";
import { Link } from 'gatsby';

const Terms = () => {
  
const { t, i18n } = useTranslation();
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <><Link to="https://profile.thedigi.app">
            <button className="btn btn btn-dark-digi btn-sm-2 rounded-8 h-50 font-size-5">
            <i className="fa fa-lock mr-9 font-size-5 " />   {t("login")}  
          </button>
          </Link>
            </>
          ),
          footerStyle: "style6",
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">{t("terms_title")}</h2>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8">
                    <h2 className="font-size-10 mb-7 text-dark-digi" >
                      {t("terms_chapter1")}
                    </h2>
                      <h3 className="font-size-9 mb-7">
                      1. {t("terms_heading1")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading1_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading1_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading1_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading1_paragraph4")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading1_paragraph5")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading1_paragraph6")}
                      </p>
                      <p className="font-size-5 heading-default-color mb-0">
                      {t("terms_heading1_paragraph7_1")}<a href="#faq">{t("faq")}</a>{t("terms_heading1_paragraph7_2")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">2. {t("terms_heading2")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading2_paragraph1")}</p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading2_paragraph2")}</p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">3. {t("terms_heading3")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading3_paragraph1")}<ul>
                        <li>{t("terms_heading3_paragraph1_bullet1")}</li>
                        <li>{t("terms_heading3_paragraph1_bullet2")}</li>
                        <li>{t("terms_heading3_paragraph1_bullet3")}</li>
                        <li>{t("terms_heading3_paragraph1_bullet4")}</li>
                        <li>{t("terms_heading3_paragraph1_bullet5")}</li>
                        <li>{t("terms_heading3_paragraph1_bullet6")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading3_paragraph2")}
                     </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">4. {t("terms_heading4")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading4_paragraph1")} </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading4_paragraph2_1")}<a href="#privacy">{t("privacy_title")}</a>{t("terms_heading4_paragraph2_2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading4_paragraph3")}                  
                      <ul>
                        <li>{t("terms_heading4_paragraph3_bullet1")}</li>
                        <li>{t("terms_heading4_paragraph3_bullet2")}</li>
                      </ul>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">5. {t("terms_heading5")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading5_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading5_paragraph2")}
                      </p>
                      <h3 className="font-size-9 mb-7 text-light-digi">
                      {t("terms_chapter2")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading5_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading5_paragraph4")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading5_paragraph5")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading5_paragraph6")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading5_paragraph7")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                    <h2 className="font-size-10 mb-7 text-dark-digi">{t("terms_chapter3")}</h2>
                    <p className="font-size-5 heading-default-color">
                    {t("terms_chapter3_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_chapter3_paragraph2")}
                      </p>
                      <h3 className="font-size-9 mb-7">6. {t("terms_heading6")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading6_paragraph1")}<a href="mailto:office@thedigi.app">office@thedigi.app</a>.
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading6_paragraph2")}</p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">7. {t("terms_heading7")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading7_paragraph1")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                    <h2 className="font-size-10 mb-7 text-dark-digi">{t("terms_chapter4")}</h2>
                      <h3 className="font-size-9 mb-7">8. {t("terms_heading8")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading8_paragraph1")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">9. {t("terms_heading9")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading9_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading9_paragraph2")}<a href="mailto:contact@thedigi.app">contact@thedigi.app</a>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading9_paragraph3")}<a href="mailto:dpo@thedigi.app">dpo@thedigi.app</a>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading9_paragraph4")}<a href="mailto:support@thedigi.app">support@thedigi.app</a>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading9_paragraph5")}<a href="mailto:office@thedigi.app">office@thedigi.app</a>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">*10. {t("terms_heading10")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading10_paragraph1")}
                      </p>
                      <h6 className="font-size-4 mb-7"><i>{t("terms_heading10_subheading1")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading10_paragraph2")}</p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading10_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading10_paragraph4")}
                      <ul>
                      <li>{t("terms_heading10_paragraph4_bullet1")}</li>
                      <li>{t("terms_heading10_paragraph4_bullet2")}</li>
                      <li>{t("terms_heading10_paragraph4_bullet2")}</li>
                      </ul>                      
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading10_paragraph5")}</p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading10_paragraph6")}<a href="#privacy">{t("privacy_title")}</a>.
                      </p>
                      <p className="font-size-5 heading-default-color">
                        <i>{t("terms_heading10_paragraph7")}</i>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">11. {t("terms_heading11")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading11_paragraph1")}
                      <ul>
                        <li>{t("terms_heading11_paragraph1_bullet1")}</li>
                        <li>{t("terms_heading11_paragraph1_bullet2")}</li>
                        <li>{t("terms_heading11_paragraph1_bullet3")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading11_paragraph2")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                    <h2 className="font-size-10 mb-7 text-dark-digi">{t("terms_chapter5")}</h2>
                      <h3 className="font-size-9 mb-7">12. {t("terms_heading12")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading12_paragraph1_1")}<a href="mailto:dpo@thedigi.app">dpo@thedigi.app</a>{t("terms_heading12_paragraph1_2")}</p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">13. {t("terms_heading13")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading13_paragraph1")}</p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading13_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading13_paragraph3")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">14. {t("terms_heading14")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading14_paragraph1")}
                      <ul>
                        <li>{t("terms_heading14_paragraph1_bullet1")}</li>
                        <li>{t("terms_heading14_paragraph1_bullet2")}</li>
                        <li>{t("terms_heading14_paragraph1_bullet3")}</li>
                        <li>{t("terms_heading14_paragraph1_bullet4")}</li>
                        <li>{t("terms_heading14_paragraph1_bullet5")}</li>
                        <li>{t("terms_heading14_paragraph1_bullet6")}</li>
                        <li>{t("terms_heading14_paragraph1_bullet7")}</li>
                        <li>{t("terms_heading14_paragraph1_bullet8")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading14_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading14_paragraph3")}<a href="mailto:contact@thedigi.app">contact@thedigi.app.</a>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">15. {t("terms_heading15")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading15_paragraph1")}
                      <ul>
                        <li>{t("terms_heading15_paragraph1_bullet1")}</li>
                        <li>{t("terms_heading15_paragraph1_bullet2")}</li>
                        <li>{t("terms_heading15_paragraph1_bullet3")}</li>
                        <li>{t("terms_heading15_paragraph1_bullet4")}</li>
                        <li>{t("terms_heading15_paragraph1_bullet5")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-4 mb-7">{t("terms_subheading1")}</h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading15_paragraph2")}
                       </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading15_paragraph3")}
                       </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">16. {t("terms_heading16")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading16_paragraph1")}
                       </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading16_paragraph2")}<ul>
                        <li>{t("terms_heading16_paragraph2_bullet1")}</li>
                        <li>{t("terms_heading16_paragraph2_bullet2")}</li>
                        <li>{t("terms_heading16_paragraph2_bullet3")}</li>
                        <li>{t("terms_heading16_paragraph2_bullet4")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading16_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading16_paragraph4")}
                      <ul>
                        <li>{t("terms_heading16_paragraph4_bullet1")}</li>
                        <li>{t("terms_heading16_paragraph4_bullet2")}</li>
                        <li>{t("terms_heading16_paragraph4_bullet3")}</li>
                        <li>{t("terms_heading16_paragraph4_bullet4")}</li>
                        <li>{t("terms_heading16_paragraph4_bullet5")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading16_paragraph5")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">17. {t("terms_heading17")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading17_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading17_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading17_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading17_paragraph4")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading17_paragraph5")}
                      <ul>
                        <li>{t("terms_heading17_paragraph5_bullet1")}</li>
                        <li>{t("terms_heading17_paragraph5_bullet2")}</li>
                        <li>{t("terms_heading17_paragraph5_bullet3")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading17_paragraph6")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">18. {t("terms_heading18")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading18_paragraph1")}
                      <ul>
                        <li>{t("terms_heading18_paragraph1_bullet1")}</li>
                        <li>{t("terms_heading18_paragraph1_bullet2")}</li>
                        <li>{t("terms_heading18_paragraph1_bullet3")}</li>
                      </ul>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">19. {t("terms_heading19")}</h3>
                      <h6 className="font-size-4 mb-7"><i>{t("terms_heading19_subheading1")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph2")}
                       </p>
                      <h6 className="font-size-4 mb-7"><i>{t("terms_heading19_subheading2")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph4")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      e-mail:<a href="mailto:kzld@cpdp.bg">kzld@cpdp.bg</a>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph5_1")}<a href="https://www.cpdp.bg/" target="_blank" rel="noopener noreferrer">{t("terms_heading19_paragraph5_2")}</a>.
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph6")}
                      </p>
                      <h6 className="font-size-4 mb-7">{t("terms_subheading2")}</h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph7_1")}<a href="mailto:support@thedigi.app">support@thedigi.app</a>{t("terms_heading19_paragraph7_2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph8")}<a href="mailto:complaints@thedigi.app">complaints@thedigi.app</a>.
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph9")}
                      <ul>
                        <li>{t("terms_heading19_paragraph9_bullet1")}</li>
                        <li>{t("terms_heading19_paragraph9_bullet2")}</li>
                        <li>{t("terms_heading19_paragraph9_bullet3")}</li>
                        <li>{t("terms_heading19_paragraph9_bullet4")}</li>
                        <li>{t("terms_heading19_paragraph9_bullet5")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph10")}</p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading19_paragraph11")}</p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                    <h2 className="font-size-10 mb-7 text-dark-digi">{t("terms_chapter6")}</h2>
                      <h3 className="font-size-9 mb-7">20. {t("terms_heading20")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading20_paragraph1")}<a href="#privacy">{t("privacy_title")}</a>.
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading20_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading20_paragraph3")}
                      </p>
                      <h6 className="font-size-4 mb-7">{t("terms_subheading3")}</h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading20_paragraph4")}
                       <ul>
                          <li>{t("terms_heading20_paragraph4_bullet1")}</li>
                          <li>{t("terms_heading20_paragraph4_bullet2")}</li>
                          <li>{t("terms_heading20_paragraph4_bullet3")}</li>
                          <li>{t("terms_heading20_paragraph4_bullet4")}</li>
                          <li>{t("terms_heading20_paragraph4_bullet5")}</li>
                          <li>{t("terms_heading20_paragraph4_bullet6")}</li>
                          <li>{t("terms_heading20_paragraph4_bullet7")}</li>
                          <li>{t("terms_heading20_paragraph4_bullet8")}</li>
                          <li>{t("terms_heading20_paragraph4_bullet9")}</li>
                          <li>{t("terms_heading20_paragraph4_bullet10")}</li>
                        </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading20_paragraph5")}
                       </p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading20_paragraph6")}
                       </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">21. {t("terms_heading21")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading21_paragraph1")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">22. {t("terms_heading22")}</h3>
                      <h6 className="font-size-4 mb-7"><i>{t("terms_heading22_subheading1")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading22_paragraph1")}
                      </p>
                      <h6 className="font-size-4 mb-7"><i>{t("terms_heading22_subheading2")}</i></h6>                      
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading22_paragraph2")}</p>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading22_paragraph3")}
                      </p>
                      <h6 className="font-size-4 mb-7"><i>{t("terms_heading22_subheading3")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading22_paragraph4")}
                       </p>
                      <h6 className="font-size-4 mb-7"><i>{t("terms_heading22_subheading4")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading22_paragraph5")}
                      </p>
                      <h6 className="font-size-4 mb-7"><i>{t("terms_heading22_subheading5")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading22_paragraph6")}
                      </p>
                      <h6 className="font-size-4 mb-7"><i>{t("terms_heading22_subheading6")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading22_paragraph7")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">23. {t("terms_heading23")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading23_paragraph1")}</p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">24. {t("terms_heading24")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading24_paragraph1")}<a href="#privacy">{t("privacy_title")}</a>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">25. {t("terms_heading25")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("terms_heading25_paragraph1")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </PageWrapper>
    </>
  );
};
export default Terms;
